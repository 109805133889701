import PropTypes from "prop-types";
// material
import { Box } from "@material-ui/core";
// ----------------------------------------------------------------------
Logo.propTypes = {
  sx: PropTypes.object,
};
export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 150, height: 30, ...sx }}>
      <img src="/static/logo.png" alt="predictwin logo" />
    </Box>
  );
}
